<template>
  <b-modal v-model="showModal" :title="`${tournament.name} - ${$t('addTeam')}`" @shown="changeFocus">
    <b-form @submit.prevent="search">
      <div class="form-group">
        <b-input-group class="mb-3">
          <b-form-input ref="inputQ" v-model="q" v-validate="'required'" name="q" :data-vv-as="$t('search') | toLowerCase" :state="validateState('q')"></b-form-input>
          <b-input-group-append>
            <b-button type="submit" variant="primary" class="px-4">
              <i class="fa fa-search"></i>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </div>
      <div class="form-group">
        <b-list-group>
          <b-list-group-item v-for="(team, ind) in teams" :key="`but-${ind}`" button @click="selectTeam(team.id)" :class="{ active: team.active }">{{ team.name }}</b-list-group-item>
        </b-list-group>
      </div>
    </b-form>
    <template slot="modal-footer">
      <b-button type="button" @click="showModal = false">{{ $t('cancel') }}</b-button>
      <b-button :disabled="!teamId" type="button" @click="sendInvitation" variant="primary">{{ $t('sendInvitation') }}</b-button>
    </template>
  </b-modal>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    openClose: {
      required: true,
      type: Boolean
    },
    tournament: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      q: '',
      teams: [],
      console: this.$route.params.console,
      teamId: null
    }
  },
  computed: {
    ...mapGetters(['lang']),
    showModal: {
      get () {
        return this.openClose
      },
      set (newValue) {
        this.$emit('update:openClose', newValue)
      }
    }
  },
  methods: {
    sendInvitation () {
      const params = {
        team_id: this.teamId
      }
      const path = `auth/${this.lang}/${this.console}/tournaments/${this.tournament.id}/send-invitation-enter-tournament`
      this.$axios.post(path, params).then(response => {
        this.$toastr.success(response.data.message)
        this.teams = this.teams.map(t => {
          t.active = false
          return t
        })
        this.teamId = null
        this.showModal = false
      })
    },
    changeFocus () {
      this.$refs.inputQ.focus()
    },
    selectTeam (teamId) {
      this.teamId = teamId
      this.teams = this.teams.map(t => {
        if (t.id === teamId) {
          t.active = true
        } else {
          t.active = false
        }
        return t
      })
    },
    search () {
      this.$validator.validate().then(result => {
        if (result) {
          const params = {
            q: this.q
          }
          const path = `auth/${this.lang}/${this.console}/teams/search`
          this.$axios.get(path, { params }).then(response => {
            const data = response.data.data.data
            this.teams = data.map(t => {
              t.active = false
              return t
            })
          })
        } else {
          this.showValidationsErrors(this.errors.items)
        }
      })
    }
  }
}
</script>
